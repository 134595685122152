<template>
  <collapse-card :model-value="true">
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Characters
      </span>
    </template>
    <template #cardContent>
      <div
        v-if="__submissionLoadingTracker?.loadingStyleGuides || __submissionLoadingTracker?.loadingSGCharacters"
        class="h-12 w-full mt-4 text-center"
      >
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        Loading ...
      </div>

      <div
        v-else
        class="py-2 px-4"
      >
        <base-select
          :model-value="submissionForm.styleGuideName"
          class="w-1/2 flex items-center text-sm text-left pr-6 mb-6"
          placeholder="-- SELECT --"
          :error="submissionFormValidations.styleGuideName"
          label="Style Guide"
          label-class="label-w-46"
          :is-required="styleGuideNameFieldState === REQUIRED"
          :options="styleGuideOptions"
          show-default-option
          :ignore-selection="true"
          @update:modelValue="handleStyleGuideSelection($event, cIndex)"
        />
        <div class="flex mb-3">
          <icon-button
            class="mr-3 w-7.5 h-7.5"
            icon="icons/plus.svg"
            :active="submissionForm.styleGuideName && submissionForm.styleGuideName.length > 0"
            active-btn-class="bg-custom-green-1 bg-opacity-20"
            active-icon-class="text-custom-green-1"
            @click="addCharacterTableRow()"
          />
          <icon-button
            class="w-7.5 h-7.5"
            icon="icons/x.svg"
            :active="isDeleteCharacterBtnActive"
            active-btn-class="bg-primary-red bg-opacity-20"
            active-icon-class="text-primary-red"
            @click="deleteCharacterTableRows()"
          />
        </div>
        <brand-assurance-table
          v-model="styleGuideCharacterTableData"
          :are-columns-sortable="false"
          root-element-class="mb-10"
          :error="submissionFormValidations.characters"
        >
          <!-- show 1 empty row if table is empty -->
          <template
            v-if="styleGuideCharacterTableData.data.length === 0"
            #customTableBody
          >
            <tr>
              <td
                class="p-2 whitespace-nowrap text-center border border-r-0 border-b-0 last:border-r border-custom-gray-3 text-xs"
              >
                    &nbsp;
              </td>
              <td
                v-for="(_, tdIndex) in styleGuideCharacterTableData.columns"
                :key="'cell' + tdIndex"
                class="p-2 whitespace-nowrap border border-r-0 border-b-0 last:border-r border-custom-gray-3 text-xs"
              >
                    &nbsp;
              </td>
            </tr>
          </template>
          <!-- inject dropdown in each cell of style guide character column -->
          <template
            v-for="(characterObj, cIndex) in styleGuideCharacterTableData.data"
            :key="'cell-slot' + cIndex"
            #[generateTableSlotName(cIndex,1)]
          >
            <base-select
              :model-value="characterObj.characterName"
              class="flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              dropdown-btn-container-class="w-full static"
              dropdown-container-class="max-w-3xl"
              :options="styleGuideCharacterOptions"
              show-default-option
              :ignore-selection="true"
              @dropdownOpened="fetchStyleGuideCharacters()"
              @update:modelValue="handleCharacterSelection($event, cIndex)"
            />
          </template>
          <!-- inject checkboxes in required columns when selected style guide is a movie -->
          <template
            v-for="(characterObj, cIndex) in styleGuideCharacterTableData.data"
            :key="'cell-slot' + cIndex"
            #[generateTableSlotName(cIndex,2)]
          >
            <base-checkbox
              :model-value="characterObj.isActorLikenessProduct"
              @update:modelValue="handleFlagSelection($event, 'isActorLikenessProduct', cIndex)"
            />
          </template>
          <template
            v-for="(characterObj, cIndex) in styleGuideCharacterTableData.data"
            :key="'cell-slot' + cIndex"
            #[generateTableSlotName(cIndex,3)]
          >
            <base-checkbox
              :model-value="characterObj.isActorLikenessPackaging"
              @update:modelValue="handleFlagSelection($event, 'isActorLikenessPackaging', cIndex)"
            />
          </template>
          <template
            v-for="(characterObj, cIndex) in styleGuideCharacterTableData.data"
            :key="'cell-slot' + cIndex"
            #[generateTableSlotName(cIndex,4)]
          >
            <base-checkbox
              :model-value="characterObj.isActorLikenessVoice"
              @update:modelValue="handleFlagSelection($event, 'isActorLikenessVoice', cIndex)"
            />
          </template>
        </brand-assurance-table>
        <base-input
          v-if="generalCharacterCommentsFieldState !== INVISIBLE"
          v-model="submissionForm.uxGenCharacterComments"
          text-area
          rows="3"
          :error="submissionFormValidations.uxGenCharacterComments"
          :is-required="generalCharacterCommentsFieldState === REQUIRED"
          container-class="w-full mb-4 flex"
          label-class="label-w-46"
          label="General Character Comments"
        />
      </div>
    </template>
  </collapse-card>
  <base-modal
    v-model="showSKUChangeConfirmationModal"
    modal-title="SKU's Deletion Message"
    @hidden="closeSKUChangeConfirmationModal()"
  >
    <template #modalBody>
      <div class="px-4 py-3">
        <div class="font-sm text-center mb-2">
          The characters selected on the submission will be removed when you change the Style Guide. The characters will be removed from associated SKU(s) as well. Do you want to continue?
        </div>
        <div class="text-center mt-2">
          <base-button
            class="mr-3"
            variant="btn-primary"
            text="Yes"
            @click="confirmSKUChangeConfirmationModal()"
          />
          <base-button
            variant="btn-link"
            text="No"
            @click="closeSKUChangeConfirmationModal()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent, reactive, computed, watch, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import useSubmissionForm from './submissionForm.js';
import useSubmissionLoadTracker from './submissionLoadTracker.js';
import useBaTable from '@/hooks/baTable.js';
import { EDITABLE, GENERIC_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE, INVISIBLE, PRESS_RELEASE_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE, REQUIRED } from '@/constants/submissions.js';
import { ADD_ATLEAST_ONE_CHARACTER, REQUIRED_FIELD } from '@/constants/validationMessages.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ERROR, FETCH_STYLE_GUIDES_CHARCTERS_FAILED, FETCH_STYLE_GUIDES_FAILED, NO_CHARACTERS_FOUND, WARNING } from '@/constants/alerts.js';

export default {
    name: 'Characters',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        IconButton: defineAsyncComponent(() => import('@/components/IconButton.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        BaseCheckbox: defineAsyncComponent(() => import('@/components/generic-components/BaseCheckbox.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    setup () {
        const store = useStore();
        const { submissionForm, updateSubmissionForm, submissionFormValidations } = useSubmissionForm();
        const { updateSubmissionLoadingTracker } = useSubmissionLoadTracker();
        const __submissionLoadingTracker = reactive({
            loadingStyleGuides: false,
            loadingSGCharacters: false
        });
        const { generateTableSlotName } = useBaTable();
        const { notificationsStack } = useToastNotifications();

        const styleGuideCharacterTableData = reactive({ columns: [], data: [] });

        const adjustCharacterTableForSgName = (sgName) => {
            let tmpCharacter;
            if (styleGuidesCharsList.value && styleGuidesCharsList.value.data) {
                tmpCharacter = styleGuidesCharsList.value.data.find(e => e.styleGuideId === submissionForm?.styleGuideId);
            }
            // Adjust the Columns
            if (tmpCharacter?.movieSgYn === 'Y') {
                styleGuideCharacterTableData.columns = [
                    {
                        label: 'Style Guide Character',
                        key: 'characterName'
                    },
                    {
                        label: 'Actor Likeness on Product',
                        key: 'isActorLikenessProduct'
                    },
                    {
                        label: 'Actor Likeness on Packaging',
                        key: 'isActorLikenessPackaging'
                    },
                    {
                        label: 'Actor Voice',
                        key: 'isActorLikenessVoice'
                    }
                ];
            } else {
                styleGuideCharacterTableData.columns = [
                    {
                        label: 'Style Guide Character',
                        key: 'characterName'
                    }
                ];
            }

            // Adjust the Data
            if (!submissionForm.characters || !submissionForm.characters.length || submissionForm.styleGuideId !== submissionForm.characters[0].styleguideId) {
                styleGuideCharacterTableData.data = [];
                updateCharactersInSubmissionForm();
            } else {
                // Set the characters
                styleGuideCharacterTableData.data = [
                    ...submissionForm.characters.map(c => {
                        const tmpChar = { ...c };
                        if (typeof tmpChar.isActorLikenessPackaging === 'string') tmpChar.isActorLikenessPackaging = tmpChar.isActorLikenessPackaging === 'Y';
                        if (typeof tmpChar.isActorLikenessProduct === 'string') tmpChar.isActorLikenessProduct = tmpChar.isActorLikenessProduct === 'Y';
                        if (typeof tmpChar.isActorLikenessVoice === 'string') tmpChar.isActorLikenessVoice = tmpChar.isActorLikenessVoice === 'Y';
                        return tmpChar;
                    })
                ];
            }
        };

        const newStyleGuideName = ref('');
        const showSKUChangeConfirmationModal = ref(false);
        const handleStyleGuideSelection = (selectedStyleGuideName) => {
            newStyleGuideName.value = selectedStyleGuideName;
            showSKUChangeConfirmationModal.value = true;
        };
        const confirmSKUChangeConfirmationModal = async () => {
            showSKUChangeConfirmationModal.value = false;
            submissionForm.styleGuideName = newStyleGuideName.value;
        };
        const closeSKUChangeConfirmationModal = () => {
            showSKUChangeConfirmationModal.value = false;
        };

        watch(
            () => submissionForm.styleGuideName,
            async () => {
                if (fetchedStyleGuides.value && fetchedStyleGuides.value.data) {
                    fetchedStyleGuides.value.data.forEach(styleGuide => {
                        if (submissionForm.styleGuideName === styleGuide.styleGuide) {
                            submissionForm.styleGuideId = styleGuide.styleGuideId;
                        }
                    });
                }
                if (submissionForm.styleGuideName && submissionForm.contractNumber) await fetchStyleGuideCharacters();
                adjustCharacterTableForSgName(submissionForm.styleGuideName);
            }
        );

        // character section validations logic
        const styleGuideNameFieldState = computed(() => {
            if ([GENERIC_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return INVISIBLE;
            } else {
                return REQUIRED;
            }
        });
        const styleGuideCharactersFieldState = computed(() => {
            if ([GENERIC_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return INVISIBLE;
            } else if ([PRESS_RELEASE_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return EDITABLE;
            } else if ([INTERACTIVE_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return submissionForm.skus.length > 0 ? REQUIRED : EDITABLE;
            } else {
                return REQUIRED;
            }
        });

        watch(
            () => submissionForm.skus,
            () => {
                if (submissionForm.skus.length > 0) {
                    submissionFormValidations.characters = styleGuideCharactersFieldState.value === REQUIRED && !(submissionForm.characters && submissionForm.characters.filter(char => char.characterName).length) ? ADD_ATLEAST_ONE_CHARACTER : '';
                } else {
                    submissionFormValidations.characters = '';
                }
            }
        );

        watch(
            () => [submissionForm.styleGuideName, submissionForm.characters],
            () => {
                submissionFormValidations.styleGuideName = styleGuideNameFieldState.value === REQUIRED && !submissionForm.styleGuideName ? REQUIRED_FIELD : '';
                submissionFormValidations.characters = styleGuideCharactersFieldState.value === REQUIRED && !(submissionForm.characters && submissionForm.characters.filter(char => char.characterName).length) ? ADD_ATLEAST_ONE_CHARACTER : '';
            },
            {
                deep: true,
                immediate: true
            }
        );

        // style guide list logic
        const fetchedStyleGuides = computed(() => store.getters['baStyleGuides/getDrmStyleGuidesList']);
        const styleGuideOptions = computed(() => fetchedStyleGuides.value && fetchedStyleGuides.value.data && fetchedStyleGuides.value.data.length ? fetchedStyleGuides.value.data.map(name => name.styleGuide) : []);
        const fetchStyleGuides = async () => {
            try {
                if (__submissionLoadingTracker.loadingStyleGuides) {
                    return;
                }

                __submissionLoadingTracker.loadingStyleGuides = true;
                await store.dispatch('baStyleGuides/fetchDrmStyleGuideContractList', {
                    params: {
                        contractNumber: submissionForm.contractNumber
                    }
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: FETCH_STYLE_GUIDES_FAILED
                });
            } finally {
                __submissionLoadingTracker.loadingStyleGuides = false;
            }
        };

        onMounted(async () => {
            await fetchStyleGuides();
            await fetchStyleGuideCharacters();
            if (submissionForm.styleGuideName) adjustCharacterTableForSgName(submissionForm.styleGuideName);
        });

        const fetchSelectedStyleGuideId = computed(() => {
            const objStyleGuide = fetchedStyleGuides.value?.data.length > 0 ? fetchedStyleGuides.value.data.find(id => submissionForm && submissionForm.styleGuideId === id.styleGuideId ? id.styleGuideId : '') : null;
            return objStyleGuide ? objStyleGuide.styleGuideId : '';
        });

        // style guide character table logic
        const styleGuidesCharsList = computed(() => store.getters['baStyleGuides/getDrmStyleGuidesCharactersList']);
        const styleGuideCharacterOptions = computed(() => {
            let opts = [];
            if (styleGuidesCharsList.value && styleGuidesCharsList.value.data && styleGuidesCharsList.value.data.length) {
                opts = styleGuidesCharsList.value.data.map(e => e.character);
            }
            return opts;
        });

        const fetchStyleGuideCharacters = async () => {
            if ([GENERIC_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                return;
            }

            try {
                if (__submissionLoadingTracker.loadingSGCharacters) return;

                if (styleGuidesCharsList.value && styleGuidesCharsList.value.data) {
                    const __firstChar = styleGuidesCharsList.value.data[0];
                    if (__firstChar && __firstChar.styleGuideId === fetchSelectedStyleGuideId.value) {
                    // Already have the characters .. dont fetch again
                        return;
                    }
                }

                __submissionLoadingTracker.loadingSGCharacters = true;
                await store.dispatch('baStyleGuides/fetchDrmStyleGuideCharactersList', {
                    params: {
                        styleGuideId: fetchSelectedStyleGuideId.value,
                        limit: 5000
                    },
                    id: submissionForm.contractNumber
                });

                if (styleGuideCharacterOptions.value.length === 0) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: NO_CHARACTERS_FOUND
                    });
                }
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: FETCH_STYLE_GUIDES_CHARCTERS_FAILED
                });
            } finally {
                __submissionLoadingTracker.loadingSGCharacters = false;
            }
        };

        const cleanCharacterObj = {
            selected: false,

            submissionCharacterKey: null,
            submissionKey: null,
            submissionId: submissionForm.submissionId,
            styleguideId: submissionForm.styleGuideId,

            characterName: '',
            characterId: null,

            isActorLikenessProduct: null,
            isActorLikenessPackaging: null,
            isActorLikenessVoice: null,

            iteration: submissionForm.iteration,
            currentStage: submissionForm.currentStage,
            currentStep: submissionForm.currentStep
        };

        const addCharacterTableRow = () => {
            styleGuideCharacterTableData.data.push({ ...cleanCharacterObj });
            updateCharactersInSubmissionForm();
        };

        const deleteCharacterTableRows = () => {
            styleGuideCharacterTableData.data = styleGuideCharacterTableData.data.filter(tableObj => !tableObj.selected);
            updateCharactersInSubmissionForm();
        };

        const isDeleteCharacterBtnActive = computed(() => styleGuideCharacterTableData.data && styleGuideCharacterTableData.data.some(tableObj => tableObj.selected));

        const updateCharactersInSubmissionForm = () => {
            updateSubmissionForm({
                characters: styleGuideCharacterTableData.data
            });
        };

        const handleCharacterSelection = (newCharacterName, cIndex) => {
            // avoid selecting duplicate character
            const matchedCharacter = styleGuideCharacterTableData.data.find(char => char.characterName === newCharacterName);
            if (!matchedCharacter) {
                const tmpChar = styleGuidesCharsList.value.data.find(e => e.character === newCharacterName);
                if (tmpChar) {
                    styleGuideCharacterTableData.data[cIndex] = { ...tmpChar, characterName: tmpChar.character };
                    delete styleGuideCharacterTableData.data[cIndex].character;
                }
            } else {
                styleGuideCharacterTableData.data[cIndex] = { ...cleanCharacterObj };
            }
            updateCharactersInSubmissionForm();
        };

        const handleFlagSelection = (newValue, flagName, cIndex) => {
            styleGuideCharacterTableData.data[cIndex][flagName] = newValue;
            updateCharactersInSubmissionForm();
        };

        watch(
            __submissionLoadingTracker,
            () => {
                updateSubmissionLoadingTracker({ ...__submissionLoadingTracker });
            },
            { deep: true, immediate: true }
        );

        const characterNamePersist = ref('');
        const hasGeneralCharacters = computed(() => {
            return styleGuideCharacterTableData.data &&
          styleGuideCharacterTableData.data.some(c => {
              characterNamePersist.value = c.characterName;
              return c.characterName?.toLowerCase().includes('(general)');
          });
        });

        const generalCharacterCommentsFieldState = computed(() => {
            if (hasGeneralCharacters.value) {
                return REQUIRED;
            } else {
                return INVISIBLE;
            }
        });

        watch(
            () => characterNamePersist.value,
            () => {
                submissionFormValidations.uxGenCharacterComments = generalCharacterCommentsFieldState.value === REQUIRED && !submissionForm.uxGenCharacterComments ? REQUIRED_FIELD : '';
            },
            {
                deep: true,
                immediate: true
            }
        );

        watch(
            () => submissionForm.uxGenCharacterComments,
            () => {
                if (submissionForm.uxGenCharacterComments !== '') {
                    submissionFormValidations.uxGenCharacterComments = '';
                } else {
                    submissionFormValidations.uxGenCharacterComments = generalCharacterCommentsFieldState.value === REQUIRED && !submissionForm.uxGenCharacterComments ? REQUIRED_FIELD : '';
                }
            }
        );

        return {
            submissionForm,
            // character section validations
            REQUIRED,
            INVISIBLE,
            submissionFormValidations,
            styleGuideNameFieldState,
            // style guide list
            styleGuideOptions,
            fetchStyleGuides,
            // style guide character table
            styleGuideCharacterOptions,
            fetchStyleGuideCharacters,
            styleGuideCharacterTableData,
            addCharacterTableRow,
            deleteCharacterTableRows,
            generateTableSlotName,
            isDeleteCharacterBtnActive,
            handleCharacterSelection,
            handleFlagSelection,
            __submissionLoadingTracker,
            showSKUChangeConfirmationModal,
            closeSKUChangeConfirmationModal,
            confirmSKUChangeConfirmationModal,
            handleStyleGuideSelection,
            hasGeneralCharacters,
            generalCharacterCommentsFieldState
        };
    }
};
</script>
